<template>
  <v-app style="background-color: #F7F8FC">
    <div class="container pb-0">
      <AppBar/>
    </div>
    <DrawerDashboard/>
    <ViewDashboard/>
  </v-app>
</template>

<script>
  import ViewDashboard from '../../components/layout/View'
  import DrawerDashboard from '../../components/layout/Drawer'
  import AppBar from '../../components/layout/AppBar'

  export default {
    name: 'IndexDashboard',
    components: {
      ViewDashboard,
      DrawerDashboard,
      AppBar
    }
  }
</script>

<style scoped>

</style>
