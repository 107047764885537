<template>
  <v-navigation-drawer
    v-model="showDrawer"
    color="#363740"
    absolute
    dark
    class="navigator"
    expand-on-hover
    mini-variant
  >
    <v-list
      class="py-0"
    >
      <v-list-item link center class="logo-content px-0 d-flex justify-center align-center v-picker--full-width">
        <img style="max-width: 42px; border-radius: 50%;" class="px-auto" :src="drawerImage">
        <h3 class="pl-4 pr-auto">{{ empresa.nombre || 'COBI' }}</h3>
      </v-list-item>
      <v-list-item 
        link 
        class="list__item" 
        @click="$router.push({ name: 'Dispositivos' })" 
        exact 
        :class="{ 'active-drawer-item': $route.name === 'Dispositivos' }"
        >
        <v-list-item-icon>
          <v-icon class="drawer-text-color">mdi-map</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="drawer-text-color">{{ getText('Dashboard') || 'Dashboard' }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link class="list__item"
        active-class="active-drawer-item" @click="showAllDispositivos = !showAllDispositivos">
        <v-list-item-icon>
          <v-icon class="drawer-text-color">mdi-tablet</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="drawer-text-color">{{ getText('Dispositivos') || 'Dispositivos' }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-expand-transition>
        <v-card v-show="showAllDispositivos" height="auto" width="100%" class="pl-2 pl-sm-4 drawer-dispositivos-list"
          elevation="0">
          <div class="d-flex align-center pl-5" v-for="(item, i) in dispositivos" :key="i"
            @click="goToDispositivoView(item)">
            <v-icon class="drawer-text-color mr-4">{{ item.icono ? item.icono : 'mdi-thermometer' }}</v-icon>
            <p class="drawer-dispositivos-item">{{ item.nombre }}</p>
          </div>
        </v-card>
      </v-expand-transition>

      <v-divider class="my-5"></v-divider>

      <v-list-item v-for="secondaryItem in secondaryItems" :key="secondaryItem.title" link class="list__item"
        :href="secondaryItem.link" target="_blank">
        <v-list-item-icon>
          <v-icon class="drawer-text-color">{{ secondaryItem.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="drawer-text-color">{{ secondaryItem.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import axios from '@/plugins/axios'

  export default {
    data () {
      return {
        secondaryItems: [
          { title: 'Soporte', icon: 'mdi-face-agent', link: 'mailto:hola@cobi.pe' },
          { title: 'Nosotros', icon: 'mdi-nature-people-outline', link: 'https://cobi.pe' },
        ],
        showAllDispositivos: false,
        textosPersonalizados: []
      }
    },
    computed: {
      ...mapGetters(['drawer']),
      ...mapState('iot', ['dispositivos']),
      ...mapState('perfil', ['empresa']),
      showDrawer: {
        get () {
          return this.drawer
        },
        set (val) {
          this.setDrawer(val)
        },
      },
      drawerImage() {
        if (!this.empresa.logo || this.empresa.logo === "") {
          return "../../assets/cobi-logo.svg"
        }
        return this.empresa.logo
      }
    },
    methods: {
      ...mapActions(['setDrawer']),
      ...mapActions('perfil', ['fetchEmpresa']),
      ...mapActions('iot', ['fetchDispositivos']),
      async fetchTextosPersonalizador () {
        const dispositivosResponse = await axios.get('/api/empresas/textos_personalizados/')
        this.textosPersonalizados = dispositivosResponse.data
      },
      async replaceDrawerTexts () {
        this.secondaryItems = this.secondaryItems.map((textoItem) => {
          return {
            ...textoItem,
            title: this.getText(textoItem.title) || textoItem.title
          }
        })
      },
      getText (name) {
        let text = null
        const index = this.textosPersonalizados.findIndex((item) => item.opcion_texto === name)
        if (index !== -1) {
          text = this.textosPersonalizados[index].texto_a_mostrar
        }
        return text
      },
      goToDispositivoView(item) {
        if (!!this.$route.params.codigo && this.$route.params.codigo === item.codigo) return
        this.$router.push({name: 'Sensores', params: {codigo: item.codigo}})
      }
    },
    async mounted () {
      await this.fetchDispositivos()
      await this.fetchTextosPersonalizador()
      await this.fetchEmpresa()
      this.replaceDrawerTexts()
    }
}
</script>

<style scoped lang="scss">
.navigator {
  color: #9FA2B4 !important;
  z-index: 99;
}


.logo-content {
  min-height: 7.71rem;
}

.list__item {
  transition: border-left-width 200ms ease-in-out;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #A4A6B3 !important;

  &:hover {
    border-left: 3px solid white;

    & .drawer-text-color {
      color: #DDE2FF !important;
      caret-color: #DDE2FF !important;
    }
  }
}

.drawer-text-color {
  color: #9e9e9e !important;
  caret-color: #9e9e9e !important;
}

.active-drawer-item {
  border-left: 3px solid white;
  color: #DDE2FF !important;
  caret-color: #DDE2FF !important;

  & .drawer-text-color {
    color: #DDE2FF !important;
    caret-color: #DDE2FF !important;
  }
}

.drawer-dispositivos-list {
  background: transparent;
  display: none;
}

.drawer-dispositivos-item {
  color: #9e9e9e !important;
  caret-color: #9e9e9e !important;
  margin: 8px 0;
}

.drawer-dispositivos-item:hover {
  color: #DDE2FF !important;
  caret-color: #DDE2FF !important;
  cursor: pointer;
}

.v-navigation-drawer--is-mouseover .drawer-dispositivos-list {
  display: block;
}
</style>
