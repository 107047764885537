<template>
  <v-main>
    <router-view />
  </v-main>
</template>

<script>
  import AppBar from './AppBar'

  export default {
    components: {
      AppBar
    },
    name: 'ViewDashboard'
  }
</script>

<style scoped>

</style>
